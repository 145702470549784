import React from 'react';
import styled from "@emotion/styled";

const AboutPageContainer = styled("div")({
    // padding: "20px",
  })

export const About = () => {
    return (
        <AboutPageContainer className="About">
            <div className="container py-4" data-bs-theme="dark">
                <div className="p-5 mb-4 bg-body rounded-3">
                    <div className="container-fluid py-5">
                        <h1 className="display-5 fw-bold">Limke Tax & Consulting, LLC (LTC)</h1>
                        <p className="col-md-8 fs-4">is a small business providing tax services for businesses and individuals in Colorado who seek a professional approach with a personal level of service. LTC is owned and operated by Chelsea Limke with the objective to provide accurate and dependable tax services for you and your small business.
                        </p>
                        <p className="col-md-8 fs-4">
                            Chelsea is a Certified Public Accountant and holds a B.S. in Accounting from California Lutheran University and a Masters degree in Tax from the University of Denver. Chelsea is originally from the metro Denver area.
                        </p>
                        <p className="col-md-8 fs-4">
                        Chelsea has over 10 years’ experience, specializing in State and Local Tax. She has experience preparing tax returns (income tax and sales tax) and providing various tax consulting services. She has worked for large accounting firms including Moss Adams, CBIZ and Grant Thornton.
                        </p>
                        <p className="col-md-8 fs-4">
                        We look forward to the opportunity to earn your business!
                        </p>
                        <button className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold" type="button" onClick={() => window.location.href='/Contact'}>Contact Us</button>
                    </div>
                </div>
            </div>
        </AboutPageContainer>
    )
}