import React from 'react';
import styled from "@emotion/styled";

const ServicesPageContainer = styled("div")({
    padding: "20px",
  })

export const Services = () => {
    return (
        <ServicesPageContainer className="Services">
           <ul>
            <li>Tax Retrun Preparation</li>
            <ul>
                <li>Businesses</li>
                <li>Individuals</li>
            </ul>
            <li>Tax Controversy</li>
            <ul>
                <li>IRS Audit Assistance</li>
                <li>State and Local Audit Assistance</li>
                <li>IRS and State Notice Assistance</li>
                <li>Voluntary Disclosures</li>
            </ul>
            <li>Tax Consulting</li>
            <ul>
                <li>Sales Tax</li>
                <ul>
                    <li>Nexus Analysis</li>
                    <li>Sales Tax Planning</li>
                    <li>Sales Tax Return Filing Support</li>
                </ul>
                <li>Property Tax</li>
                <ul>
                    <li>Business Property Tax Filing Support</li>
                    <li>BUsiness Property Tax Review and Appeals</li>
                </ul>
                <li> Tax Research</li>
                <li>Tax Registrations</li>
            </ul>
           </ul>
      </ServicesPageContainer>
    )
}