import { ImageFill } from 'react-bootstrap-icons';
export const NavBar = () => {
    return (
        <nav className="navbar navbar-expand-lg" data-bs-theme="dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="/"><ImageFill color='white' size='32'/></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                    <a className="nav-link" href="/About">About</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/Services">Services</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/Contact">Contact</a>
                    </li>
                </ul>
                </div>
            </div>
        </nav>
    )
}