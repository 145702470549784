import React from 'react';

import { ImageFill, EnvelopeFill, TelephoneFill } from 'react-bootstrap-icons';

export const Footer = () => {
    const pathname = window.location.pathname.toLowerCase();
    const aboutClass = pathname === "/about" ? "text-body-primary" : "text-body-secondary";
    const servicesClass = pathname === "/services" ? "text-body-primary" : "text-body-secondary";
    return (
        <div className="bg-dark border-top" data-bs-theme="dark">
            <div className="container">
            <footer className="row py-5 ps-5">
                <div className="col-4 mb-3">
                <ImageFill color='white' size='32'/>
                <p className="text-body-secondary">&copy; 2024</p>
                </div>

                <div className="col-4 mb-3">
                <ul className="nav flex-column">
                    <li className="nav-item mb-2"><a href="/About" className={`nav-link p-0 ${aboutClass}`}>About</a></li>
                    <li className="nav-item mb-2"><a href="/Services" className={`nav-link p-0 ${servicesClass}`}>Services</a></li>
                </ul>
                </div>
                <div className="col mb-3">
                <ul className="nav flex-column">
                    <li className="nav-item mb-2">
                        <a  href="mailto: chelsea@limketax.com"><EnvelopeFill color="white"/> <span className="text-white">chelsea@limketax.com</span></a>
                    </li>
                    <li className="nav-item mb-2"><TelephoneFill color="white"/> <span className="text-white">(303) 809-5261</span></li>
                </ul>
                </div>
            </footer>
            </div>
        </div>
    )
}