import React from 'react';
import styled from "@emotion/styled";
import backgroundImage from "../temp-background.png"

const HomePageContainer = styled("div")({
})

const HeroDivider = styled("div")({
  width: "100%",
  height: "3rem",
  backgroundColor: "rgba(0, 0, 0, .1)",
  border: "solid rgba(0, 0, 0, .15)",
  borderWidth: "1px 0",
  boxShadow: "inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15)"
})

export const HomePage = () => {
    return (
    <HomePageContainer className="App">

      <div className="px-4 py-5 my-5 text-center">
        <h1 className="display-5 fw-bold">THIS PAGE IS UNDER CONSTRUCTION!</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">Come back soon to see more. Need to get in touch ASAP? Reach out to Jarod at <a href="mailto: jarod.limke@gmail.com">jarod.limke@gmail.com</a></p>
        </div>
      </div>

      <HeroDivider className="b-example-divider"></HeroDivider>

      <div className="container col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img src={backgroundImage} className="d-block mx-lg-auto img-fluid" alt="Limke Tax" width="700" height="500" loading="lazy"/>
          </div>
          <div className="col-lg-6">
            <h1 className="display-5 fw-bold lh-1 mb-3">Multiple Services</h1>
            <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <button
                type="button"
                className="btn btn-outline-info btn-lg px-4 me-sm-2 fw-bold"
                onClick={() => window.location.href='/Services'}
              >Services</button>
            </div>
          </div>
        </div>
      </div>
  </HomePageContainer>
    )
}