import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import styled from "@emotion/styled";

import { NavBar } from './components/NavBar';
import { Footer } from "./components/Footer";
import { HomePage } from './pages/HomePage';
import { About } from './pages/About';
import { Services } from './pages/Services';
import { Contact } from './pages/Contact';
import { Route404 } from './pages/404';

const AppContainer = styled("div")({
  backgroundColor: "#093C52",
  color: "#F5F5F5",
})

const RouteContainer = styled("div")({
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  })

const routes = [
  {
      path: "/",
      element: <HomePage/>,
  },
  {
      path: "/About",
      element: <About/>,
  },
  {
      path: "/Services",
      element: <Services/>,
  },
  {
      path: "/Contact",
      element: <Contact/>,
  },
  {
      path: "*",
      element: <Route404/>
  }
]

const router = createBrowserRouter(routes)


export const App = () => {
  return (
      <AppContainer>
      <NavBar/>
        <RouteContainer>
          <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
        </RouteContainer>
        <Footer/>
      </AppContainer>
  );
}

export default App;
