import React from 'react';
import styled from "@emotion/styled";
import { EnvelopeFill, TelephoneFill } from 'react-bootstrap-icons';

const ContactPageContainer = styled("div")({
padding: "20px"
})

export const Contact = () => {
    return (
        <ContactPageContainer className="Contact container col-xl-10 col-xxl-8 px-4 py-5" data-bs-theme="dark">

            <div className="row g-lg-5">
                <div className="col-lg-7 py-2">
                    <h3 className="display-6 fw-bold lh-1 text-body-emphasis mb-3">Contact Information</h3>
                    <ul className="list-unstyled ps-0 mt-3">
                    <li>
                        <a
                            className="col-lg-10 fs-4 icon-link link-warning link-underline-warning link-underline-opacity-25 mb-1" 
                            href="mailto: chelsea@limketax.com"
                        >
                            <EnvelopeFill/> <span>chelsea@limketax.com</span>
                        </a>
                    </li>
                    <li>
                    <div className="col-lg-10 fs-4 icon-link link-warning link-underline-warning link-underline-opacity-25 mb-1">
                        <TelephoneFill /> <span>(303) 809-5261</span>
                    </div>
                    </li>
                    </ul>
                    <div className="ratio ratio-21x9">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d98356.54044553959!2d-105.28977081870542!3d39.61274884616935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b778be497a565%3A0x6cc181a58598c24b!2sMorrison%2C%20CO%2080465!5e0!3m2!1sen!2sus!4v1729614502049!5m2!1sen!2sus" 

                            style={{border:"0"}}
                            allowFullScreen={false}
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div className="col-md-10 mx-auto col-lg-5 py-2">
                    <form className="p-5 p-md-5 border rounded-3 bg-body-tertiary">
                    <h3>General Contact Form</h3>
                    <div className="form-floating mb-3">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                        <label htmlFor="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating mb-3">

                    <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} style={{height: "100%"}}></textarea>
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
                    </div>
                    <button
                        className="w-100 btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
                        type="submit"
                        disabled={true}
                    >Under Construction</button>
                    <hr className="my-4"/>
                    <small className="text-body-secondary">Footer</small>
                    </form>
                </div>
            </div>
        </ContactPageContainer>
    )
}